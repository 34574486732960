<template>
	<div class="tabs-wrapper" @click="onClick">
		<b-tabs>
			<b-tab
				v-for="(tab, i) in tabs" :key="i"
				:title-link-class="`w-active-border-color-${tab.borderColor || 'primary'}`"
				@click="$emit('tab-changed', tab.id)"
				:disabled="i != 0 && isLocked"
				:active="tab.id == activeTab"
			>
				<template #title>
					<icon-closed-lock-svg v-if="i != 0 && isLocked" class="icon" />
					{{tab.title}}
					<icon-information-svg v-if="isTeacher && tab.tooltip" id="quiz-popover" v-b-popover.top.click.blur.hover.viewport="tab.tooltip" @click.prevent.stop="" class="icon ml-3" />
				</template>
			</b-tab>
		</b-tabs>
		<b-button
			v-if="(isTeacher || isLocked) && lesson.is_searchable && lesson.quiz"
			class="w-hover-color-white w-hover-background-primary" variant="outline-blue-button"
			@click="showCreateTaskModal"
		>
			{{ $t('Zadať ako úlohu') }}
		</b-button>
		<a-create-task-modal v-if="isTeacher" ref="create-task-modal" :course="lesson" @createTask="createTask"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

import wAxios from '@/plugins/w/axios'

const api = {
	createTask: (gid, task)	=> 	wAxios.post_auth_data(`v1/group-admin/groups/${gid}/tasks`, task),
}

export default {
	components: {
		'a-create-task-modal': () => import('@/plugins/lib@groups/groups/modals/a-create-task-modal.vue'),
		'icon-information-svg': () => import('@/plugins/appzmudri/_theme/icon/information.svg?inline'),
		'icon-closed-lock-svg': () => import('@/plugins/appzmudri@component/course-card/assets/closed-lock.svg?inline')
	},

	props: {
		tabs: {
			type: Array,
			required: true
		},
		lesson: {
			type: Object,
			required: false,
		},
		activeTab: {
			type: String,
			required: true
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		]),
		isTeacher() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		},
		isLocked() {
			return this.lesson.is_public && !this.isLoggedIn
		}
	},

	methods: {
		onClick() {
			if(!this.isLoggedIn) {
				localStorage.setItem('LAST_FROM_ROUTE', window.location.pathname)
				this.EventBus.$emit('locked-content-accessed')
			}
		},
		async createTask(task) {
			if(!this.isTeacher) return
			const groupId = task.group_id
			delete task.group_id
			
			this.$wToast.clear_loading()
			try {
				await api.createTask(groupId, task)
				this.$wToast.success('Úloha úspešne zadaná')
			} catch (e) {
				this.$wToast.error(e)
			}
			this.$wToast.stopLoading()
		},
		showCreateTaskModal() {
			if (!this.isLoggedIn) {
				return
			}

			this.$refs['create-task-modal'].showModal()
		}
	}
}
</script>

<style lang="scss" scoped>
.tabs-wrapper {
	border-bottom: 1px solid var(--a-color-blue-border);
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
	row-gap: 15px;

	@media (min-width: 1440px) {
		flex-direction: row;
		row-gap: 0;
		column-gap: 15px;
	}
}

.btn {
	padding-top: .5rem;
	padding-bottom: .5rem;
	white-space: nowrap;
	margin-bottom: 15px;

	@media (min-width: 1440px) {
		margin-bottom: 0;
	}
}

::v-deep {
	.tabs {
		max-width: 100%;
	}

	.nav-tabs {
		border: 0;
	}

	.nav-item {
		cursor: pointer;
	}
}

@media only screen and (max-width: 575px) {
	.tabs {
		order: 1;
	}

	.btn {
		margin-left: auto;
		margin-top: 1rem;
	}
}

.icon {
	width: 30px;
	height: 30px;
	outline: none;
	color: var(--a-color-primary);
}
</style>
